import { isMobile } from "../../utils/utility";
import {
  GET_CISO_REQUEST,
  GET_CISO_REQUEST_SUCCESS,
  GET_CISO_REQUEST_FAILURE,
  GET_CIS_DASHBOARD_REQUEST,
  GET_CIS_DASHBOARD_REQUEST_FAILURE,
  GET_CIS_DASHBOARD_REQUEST_SUCCESS,
  GET_CIS_IMPACTED_AREA_REQUEST,
  GET_CIS_IMPACTED_AREA_REQUEST_FAILURE,
  GET_CIS_IMPACTED_AREA_REQUEST_SUCCESS,
  GET_ORGANIZATION_DATA,
  GET_UPDATE_DATA_SECURITY_DOMAIN,
  GET_FFIEC_REQUEST_SUCCESS,
  SET_IMPACTED_NEW_AREA,
  SELECT_IMPACTED_NEW_AREA_ID,
  SET_ACTIVE_TAB_DATA,
  SET_ACTIVE_TAB_LIST_DATA,
  REMOVE_FREOM_ACTIVE_TAB_LIST_DATA,
  GET_CMMC_DATA_REQUEST,
  GET_CMMC_DATA_SUCCESS,
  GET_CMMC_DATA_FAILURE,
} from "./actionTypes";

const initialState = {
  cisoData: undefined,
  cisDashboard: undefined,
  impactedAreas: {},
  impactedNewAreas: {},
  isLoading: false,
  error: undefined,
  cisOrganizationData: undefined,
  getUpdateDataSecurityDomain: undefined,
  notificationData: [],
  selectedImpactedAreaIds: null,
  reduxSelectedSubCatTab: "NIST",
  reduxSelectedCatTAb: "",
  cmmcData: {},
  activeTabsList: [
    // {
    //   name: "OVERVIEW",
    //   link: "/ciso/OVERVIEW",
    // },
    {
      name: "OVERVIEW",
      link: "/ciso/cyber-risk",
    },
  ],
};

export const cyberRiskReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CISO_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        cisoData: data,
        isLoading: false,
      };
    }
    case GET_FFIEC_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        FFIECData: data,
        isLoading: false,
      };
    }
    case GET_CIS_DASHBOARD_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        cisDashboard: data,
        isLoading: false,
      };
    }
    case GET_CIS_IMPACTED_AREA_REQUEST_SUCCESS: {
      const { data, category } = action;

      const currImpactedAreas = state.impactedAreas;

      if (!currImpactedAreas[category]) {
        currImpactedAreas[category] = data;
      }

      return {
        ...state,
        impactedAreas: currImpactedAreas,
        isLoading: false,
      };
    }
    case GET_CISO_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case GET_CIS_DASHBOARD_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case GET_CIS_IMPACTED_AREA_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case GET_CIS_IMPACTED_AREA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_CISO_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_CIS_DASHBOARD_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    // case GET_ORGANIZATION_DATA: {
    //   const { data } = action;

    //   return {
    //     ...state,
    //     cisOrganizationData: data,
    //     isLoading: false,
    //   };
    // }
    case GET_UPDATE_DATA_SECURITY_DOMAIN: {
      const { data } = action;

      return {
        ...state,
        getUpdateDataSecurityDomain: data,
        isLoading: false,
      };
    }

    case SET_IMPACTED_NEW_AREA: {
      const { data } = action;

      return {
        ...state,
        impactedNewAreas: data?.impactedArea,
        notificationData: data?.notificationData,
      };
    }

    case SELECT_IMPACTED_NEW_AREA_ID: {
      const { data } = action;

      return {
        ...state,
        selectedImpactedAreaIds: data,
      };
    }

    case SET_ACTIVE_TAB_DATA: {
      const { data } = action;

      return {
        ...state,
        reduxSelectedSubCatTab: data.subCat,
        reduxSelectedCatTAb: data.category,
      };
    }

    case SET_ACTIVE_TAB_LIST_DATA: {
      const { data } = action;
      let temp = [...state.activeTabsList];

      while (isMobile() && temp.length >= 2) {
        temp.shift();
      }
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].name == data.name) {
          return {
            ...state,
          };
        }
      }

      temp.push(data);

      return {
        ...state,
        activeTabsList: temp,
      };
    }

    case REMOVE_FREOM_ACTIVE_TAB_LIST_DATA: {
      const { data } = action;
      let temp = [...state.activeTabsList];

      temp = temp.filter((e) => e.name !== data.name);

      return {
        ...state,
        activeTabsList: temp,
      };
    }

    case GET_CMMC_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_CMMC_DATA_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        isLoading: false,
        cmmcData: data,
      };
    }
    case GET_CMMC_DATA_FAILURE: {
    }

    default:
      return {
        ...state,
      };
  }
};
