import React, { useState } from "react";
import faqImg from "../../../assets/illustrations/faq.svg";
import "./index.scss";
import { AuthApi } from "../../../services/api.service";
import { showError, showSuccess } from "../../../utils/service";
import { useSelector } from "react-redux";
import Loader from "../../../Shared/Loader";
import Chatbot from "../Chatbot";

const Help = () => {
  // states
  const [isOpen, setIsOpen] = useState(false);
  const [isqueryFormOpen, setIsqueryFormOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    title: "",
    description: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  // redux states
  const myDetails = useSelector((state) => state.myDetails.data);

  const handleQuerySubmit = async () => {
    setIsLoading(true);
    await AuthApi.post("/ticket/", {
      type: fieldValues?.title,
      description: fieldValues?.description,
      submittedBy: myDetails?.email || "guest",
    })
      .then((res) => {
        if (res?.data?.code == 200) {
          showSuccess(res?.data?.message);
        } else {
          showError(res?.data?.message);
        }

        setFieldValues({
          title: "",
          description: "",
        });
      })
      .catch((err) => {
        showError(err?.data?.message);
      });

    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="global-popup">
          <Loader />
        </div>
      )}

      {isMobileOpen && <Chatbot setIsMobileOpen={setIsMobileOpen} />}

      <div className="help">
        {/* Trigger */}
        {!isOpen && (
          <div onClick={() => setIsOpen(true)} className="container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                clipRule="evenodd"
              />
            </svg>

            <div className="help-text">Help</div>
          </div>
        )}

        {/* Container */}
        <div className={`body-sub ${isOpen ? "active" : "inactive"}`}>
          <div className="heading-cont">
            <p>Help</p>

            <div>
              {isqueryFormOpen && (
                <svg
                  onClick={() => {
                    setIsOpen(false);
                    setIsqueryFormOpen(false);
                    setIsPopUpOpen(true);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                  />
                </svg>
              )}

              <svg
                onClick={() => setIsOpen(false)}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>

          <div
            className="messages-cont"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <a
              href="#"
              className="tab"
              onClick={() => {
                setIsMobileOpen(true);
                setIsOpen(false);
              }}
            >
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                width="200px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6 4m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
                <path d="M12 2v2"></path>
                <path d="M9 12v9"></path>
                <path d="M15 12v9"></path>
                <path d="M5 16l4 -2"></path>
                <path d="M15 14l4 2"></path>
                <path d="M9 18h6"></path>
                <path d="M10 8v.01"></path>
                <path d="M14 8v.01"></path>
              </svg>
              <div>Claribot</div>
            </a>

            <a
              href="https://csrc.nist.gov/glossary"
              target="_blank"
              className="tab"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 6h.008v.008H6V6Z"
                />
              </svg>
              <div>Glossary</div>
            </a>

            <a
              href="https://claribel.net/product-manual"
              target="_blank"
              className="tab"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
              </svg>

              <div>Product Manual</div>
            </a>

            <a
              onClick={() => setIsqueryFormOpen(true)}
              href="#"
              className="tab"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  clipRule="evenodd"
                />
              </svg>

              <div>Query</div>
            </a>

            <form
              className={`${isqueryFormOpen && "query-form-active"} query-form`}
            >
              <select
                className={
                  fieldValues.title == ""
                    ? "select-placeholder"
                    : "select-selected"
                }
                onChange={(e) => {
                  setFieldValues({ ...fieldValues, title: e.target.value });
                }}
                value={fieldValues?.title}
                placeholder="Select type"
              >
                <option disabled value="">
                  Select type
                </option>
                <option value="Bug">Bug</option>
                <option value="Enhancement">Enhancement</option>
                <option value="Feedback">Feedback</option>
              </select>

              <textarea
                onChange={(e) =>
                  setFieldValues({
                    ...fieldValues,
                    description: e.target.value,
                  })
                }
                value={fieldValues?.description}
                placeholder="Having any doubts?"
              />

              <div className="action-cont">
                <div
                  onClick={() => setIsqueryFormOpen(false)}
                  className="close-query-box"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                    />
                  </svg>
                </div>

                <button
                  disabled={!fieldValues?.title || !fieldValues?.description}
                  onClick={async (e) => {
                    e.preventDefault();
                    await handleQuerySubmit();
                    setIsqueryFormOpen(false);
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        {isPopUpOpen && (
          <div className="popUp">
            <div className="cont">
              <div className="heading-cont">
                <p>Help</p>

                <div>
                  <svg
                    onClick={() => setIsPopUpOpen(false)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>

              <form
                className={`${
                  isqueryFormOpen && "query-form-active"
                } query-form`}
              >
                <select
                  className={
                    fieldValues.title == ""
                      ? "select-placeholder"
                      : "select-selected"
                  }
                  onChange={(e) => {
                    setFieldValues({ ...fieldValues, title: e.target.value });
                  }}
                  value={fieldValues?.title}
                  placeholder="Select type"
                >
                  <option disabled value="">
                    Select type
                  </option>
                  <option value="Bug">Bug</option>
                  <option value="Enhancement">Enhancement</option>
                  <option value="Feedback">Feedback</option>
                </select>

                <textarea
                  onChange={(e) =>
                    setFieldValues({
                      ...fieldValues,
                      description: e.target.value,
                    })
                  }
                  value={fieldValues?.description}
                  placeholder="Having any doubts?"
                />

                <div className="action-cont">
                  <div></div>

                  <button
                    disabled={!fieldValues?.title || !fieldValues?.description}
                    onClick={async (e) => {
                      e.preventDefault();
                      await handleQuerySubmit();
                      setIsqueryFormOpen(false);
                      setIsPopUpOpen(false);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Help;
